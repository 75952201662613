import MicroFrontendConfig from './types/MicroFrontendConfig';
import Role from './types/Role';

const hostedMicroFrontends: Array<MicroFrontendConfig> = [
  {
    id: 'enterprise-portal',
    description: 'Enterprise Portal',
    path: '/enterprise-portal',
    icon: 'faBuilding',
    localPort: 8001,
    authorizedRoles: [Role.ClientServices, Role.Engineer],
  },
  {
    id: 'outreach-manager',
    description: 'Outreach Manager',
    path: '/outreach-manager',
    icon: 'faMegaphone',
    localPort: 8003,
    authorizedRoles: [Role.Engineer],
  },
  {
    id: 'billing-dashboard',
    description: 'Billing Dashboard',
    path: '/billing-dashboard',
    icon: 'faDollarSign',
    localPort: 8004,
    authorizedRoles: [Role.BillingAdmin, Role.Engineer],
  },
];

export default hostedMicroFrontends;
