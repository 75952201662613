import { Box, Grid, Typography } from '@mui/material';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
function Notice(props: any) {
  const { children, ...propsWithoutChildren } = props;
  return (
    <Box {...propsWithoutChildren}>
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="h6">{children}</Typography>
      </Grid>
    </Box>
  );
}

export default Notice;
