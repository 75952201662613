import { CssBaseline } from '@mui/material';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import { AuthenticationProvider } from '@onemedical/auth';
import ApolloProvider from '@onemedical/graphql-auth';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import hostedMicroFrontends from './hostedMicroFrontends';

const scope = Array.from(
  new Set(
    Object.entries(hostedMicroFrontends).reduce(
      (acc, app) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (app[1] as any).oauthScope ? acc.concat((app[1] as any).oauthScope.split(' ')) : acc,
      ['profile', 'email'],
    ),
  ),
).join(' ');

const generateClassName = createGenerateClassName({
  seed: 'management-container',
});

render(
  <BrowserRouter>
    <AuthenticationProvider
      auth0Domain={process.env.REACT_APP_AUTH_PROVIDER_URL!}
      auth0ClientId={process.env.REACT_APP_AUTH_CLIENT_ID!}
      auth0Connection={process.env.REACT_APP_AUTH0_CONNECTION}
      scope={scope}
      tokenStorage={(window as any).Cypress ? 'localstorage' : 'memory'} // eslint-disable-line @typescript-eslint/no-explicit-any
    >
      <ApolloProvider uri={process.env.REACT_APP_GRAPHQL_API_URL!}>
        <StylesProvider generateClassName={generateClassName}>
          <CssBaseline />
          <App />
        </StylesProvider>
      </ApolloProvider>
    </AuthenticationProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
