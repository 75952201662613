import * as Icons from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Grid, Link, Paper, Typography } from '@mui/material';

import Notice from '../components/Notice';
import MicroFrontendConfig from '../types/MicroFrontendConfig';

function HomePage({
  microFrontends,
  loading,
}: {
  microFrontends: Array<MicroFrontendConfig>;
  loading: boolean;
}) {
  return (
    <Container>
      <Box py={5}>
        <Typography variant="h4">Management Console</Typography>
        <Paper>
          <Box p={5} mt={5}>
            {microFrontends.length === 0 ? (
              <Notice>{loading ? 'Loading . . .' : "You don't have access to any apps."}</Notice>
            ) : (
              <Grid container spacing={3}>
                {microFrontends.map((mf) => (
                  <Grid key={mf.id} item xs={6} md={3}>
                    <FontAwesomeIcon
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      icon={(Icons as { [key: string]: any })[mf.icon]}
                      size="lg"
                    />
                    &nbsp;&nbsp;
                    <Link href={mf.path} underline="hover">
                      {mf.description}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default HomePage;
